import { AppConfig, AppLocation } from '@root-config/types'
import { hideLoader, showLoader, truthy, urlApp } from '@lasso/shared/utils'
import { navigateToUrl, registerApplication as registerApplicationBase, start as startApplication } from 'single-spa'
import { isAppActive, normalizeLocation } from '@root-config/utils'

const registerApplication = ({ id, activeWhen, loader = 'mf', shouldHideLoader = true }: AppConfig) => {
  registerApplicationBase({
    name: id,
    app: async () => {
      if (loader) {
        showLoader(loader)
      }

      try {
        return id === '@lasso/root-config' ? import('./app/main') : await import(/* @vite-ignore */ id)
      }
      finally {
        if (loader && shouldHideLoader) {
          hideLoader(loader)
        }
      }
    },
    activeWhen: location => isAppActive(activeWhen, normalizeLocation(location)),
  })
}

export const registerApplications = (apps: Array<AppConfig | null>) => {
  apps
    .filter(truthy)
    .forEach(registerApplication)
}

export const initRouter = (
  getRedirectPath: (location: AppLocation) => string | null,
) => {
  const redirect = () => {
    const path = getRedirectPath(normalizeLocation(window.location))

    if (path) {
      navigateToUrl(urlApp(path))
    }
  }

  window.addEventListener('single-spa:routing-event', redirect)
  redirect()
}

export { startApplication }
